<template>
  <div>
    <heads :backs="1" :msg="title_msg" :states="1"></heads>
    <div class="image">
      <img src="../../static/images/my_index/success.png" alt="">
    </div>
    <div class="msg">
      <p class="gxtest">恭喜您</p>
      <p class="msgtest">兑换码已经领取成功</p>
      <p class="codes">{{ $route.query.id }}</p>
      <button id="copy_text" class="clickCopy" :data-clipboard-text="dhCode" @click="copy()">点击复制兑换码</button>
      <p v-show="!shows" class="loginPhone">请您用手机号{{ phone }}</p>
      <p v-show="!shows" class="msgLogin">登录"筑龙学社APP"进入个人中心-兑换码中使用</p>
      <p v-show="shows" class="of">进入个人中心-兑换码中使用</p>
    </div>
  </div>
</template>
<script>
import heads from '@/compontens/heads.vue'
import Cookie from 'js-cookie'
import { Toast } from 'vant'
import Clipboard from 'clipboard'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '领取成功',
      phone: '',
      dhCode: '',
      shows: true
    }
  },
  mounted() {
    this.dhCode = this.$route.query.id
    if (this.$route.query.phone) {
      this.phone = this.$route.query.phone
    }
    if (!Cookie.get('uid')) {
      this.shows = false
    }
  },
  methods: {
    copy() {
      var clipboard = new Clipboard('#copy_text')
      clipboard.on('success', (e) => {
        console.log(e.text)
        Toast('已复制兑换码到剪切板')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.of{
    text-align: center;
    color: #999999;
}
.msgLogin{
    text-align: center;
    color: #999999;
}
.loginPhone{
    text-align: center;
    color: #999999;
    margin-bottom: 10px;
}
.clickCopy{
    width: 388px;
    height: 72px;
    background: #ee2e2e;
    color: #fff;
    font-size: 32px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
}
.codes{
    width: 388px;
    height: 72px;
    background: #F3F3F3 ;
    font-size: 32px;
    line-height: 72px;
    text-align: center;
    margin: 0 auto;
    margin-top: 46px;
}
.msgtest{
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    padding-top: 12px;
}
.gxtest{
    font-size: 44px;
color: #222222;
font-weight: 600;
text-align: center;
padding-top: 26px;
}
.image{
    img{
        width: 332px;
        height: 268px;
        margin: 0 auto;
        display: block;
        margin-top: 38px;
    }
}
</style>
